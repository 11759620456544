// ヘッダー
import React from 'react';
import { Link } from 'gatsby';

export const Pagination = props => {
  // 元パス スラッシュなし 最初のページのパス スラッシュなし
  let { nowPage, start, end, rootPath, firstPath } = props;

  // 前へ・後へのパス
  // 前のページが 1 なら最初のページのパスを設定
  let prevPath =
    Number(nowPage) - 1 === 1
      ? firstPath
      : `${rootPath}/${Number(nowPage) - 1}`;
  let nextPath = `${rootPath}/${Number(nowPage) + 1}`;

  // 初めのページか
  let firstFlag = Number(nowPage) === 1 ? true : false;
  // 最後のページか
  let lastFlag = Number(nowPage) === Number(end) ? true : false;

  // ページ番号の要素
  let pageList = [];
  for (let i = Number(start); i <= Number(end); i++) {
    // ページのパス設定
    let path = i === 1 ? firstPath : `${rootPath}/${i}`;

    if (Number(nowPage) === i) {
      pageList.push(
        <div
          key={i}
          className="flex items-center h-full mr-2 border-solid border border-blue-300 bg-blue-300"
        >
          <span className="px-2 text-white">{i}</span>
        </div>
      );
    } else {
      pageList.push(
        <div
          key={i}
          className="h-full mr-2 border-solid border border-blue-300"
        >
          <Link to={path} className="flex items-center h-full px-2">
            {i}
          </Link>
        </div>
      );
    }
  }

  return (
    <div className="flex justify-center items-center text-lg h-10 w-2/5 mx-auto">
      {firstFlag ? (
        <div className="flex items-center h-full mr-2 border-solid border border-blue-300">
          <i className="material-icons block px-2">chevron_left</i>
        </div>
      ) : (
        <div className="h-full mr-2 border-solid border border-blue-300">
          <Link to={prevPath} className="flex items-center h-full">
            <i className="material-icons block px-2">chevron_left</i>
          </Link>
        </div>
      )}
      {pageList}
      {lastFlag ? (
        <div className="flex items-center h-full border-solid border border-blue-300">
          <i className="material-icons block px-2">chevron_right</i>
        </div>
      ) : (
        <div className="h-full border-solid border border-blue-300">
          <Link to={nextPath} className="flex items-center h-full">
            <i className="material-icons block px-2">chevron_right</i>
          </Link>
        </div>
      )}
    </div>
  );
};

// ページネーション計算用関数
export const paginationCalc = (totalListPage, prev, next, nowPage) => {
  /*
  totalListPage: ページネーションの総数
  pagination: 出力するページネーション数
  prev: 前へのページネーション数
  next: 後へのページネーション数
  nowPage: 現在のページ数
  */
  let start = 1;
  let end = 1;

  // 総ページと出力したいページネーション数が以下なら終了
  if (prev + next + 1 >= totalListPage) {
    end = totalListPage;
    return { start, end };
  }

  // 前へと後へではみ出す分を算出
  // Pattern1
  let prevOffset = Math.max(prev - nowPage + 1, 0);
  let nextOffset = Math.max(nowPage + next - totalListPage, 0);
  // Pattern2
  // let prevOffset = Math.abs(Math.min(nowPage - prev - 1, 0));
  // let nextOffset = Math.abs(Math.min(totalListPage - (nowPage + next), 0));

  start = Math.max(nowPage - (prev + nextOffset), 1);
  end = Math.min(nowPage + next + prevOffset, totalListPage);

  return { start, end };
};
