// ヘッダー
import React from 'react';
import { Link } from 'gatsby';
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';

export const BlogCard = props => {
  // ブログデータ
  let { blogData, ...attr } = props;

  // 追加class
  // アニメーションあり
  // let addClass = 'w-11/12 lg:w-full lg:max-w-xs xl:max-w-sm shadow-xl lg:min-h-580px mx-auto lg:mx-0 transition-transform duration-150 ease-in-out transform lg:hover:scale-110 mb-8'
  // アニメーションなし
  let addClass = 'lg:w-12/25 shadow-xl lg:min-h-580px mx-auto lg:mx-0 mb-8';
  attr.className = attr.className ? addClass + ' ' + attr.className : addClass;

  // 各データ
  let {
    title,
    description,
    date,
    category,
    tags,
    heroImage
  } = blogData.frontmatter;

  // 画像
  let gatsbyImageData = heroImage ? getImage(heroImage) : null;

  return (
    <div {...attr}>
      {/* 画像 */}
      <Link to={`/blog/${blogData.slug}`}>
        {/* 無いなら no-image */}
        {heroImage ? (
          <GatsbyImage image={gatsbyImageData} />
        ) : (
          <StaticImage src="../../static/img/no-image.jpg" />
        )}
      </Link>
      {/* title等 */}
      <div className="w-11/12 pt-2 pb-3 mx-auto text-sm">
        {/* time category */}
        <div className="mb-1 flex justify-between">
          {/* category 存在するなら出力 */}
          {category && (
            <div>
              <Link
                to={`/category/${category}`}
                className="flex items-center hover:text-blue-300"
              >
                <span className="material-icons">folder_open</span>
                {category}
              </Link>
            </div>
          )}

          {/* time 存在するなら出力*/}
          {date && (
            <div className="flex items-center">
              <span className="material-icons">today</span>
              <time dateTime={date} className="">
                {date}
              </time>
            </div>
          )}
        </div>
        {/* title */}
        <h3 className="mb-1 text-2xl font-bold hover:text-blue-300">
          <Link to={`/blog/${blogData.slug}`} className="block">
            {title}
          </Link>
        </h3>
        {/* description 存在するなら出力*/}
        {description && (
          <p className="hover:text-blue-300 mb-3 break-all">
            <Link to={`/blog/${blogData.slug}`} className="block">
              {description}
            </Link>
          </p>
        )}
        {/* tag 存在するなら出力*/}
        {Array.isArray(tags) && (
          <div>
            {tags.map((tag, i) => (
              <Link
                key={i}
                to={`/tag/${tag}`}
                className="inline-block mt-1 mr-1 py-1 px-4 border-solid border-2 border-blue-300
                               rounded-full hover:bg-blue-300 hover:text-white"
              >
                {tag}
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export const BlogCardRow = props => {
  // ブログデータ
  let { children, ...attr } = props;

  // 追加class
  let addClass = '';
  attr.className = attr.className ? addClass + ' ' + attr.className : addClass;

  return <div {...attr}>{children}</div>;
};

// ブログカード用のスライス
export const blogListSlice = (blogData, limit) => {
  /*
  blogData 取得したブログデータ配列
  limit スライスする個数
  */
  let resultArray = [];
  // ループ終了
  let loopEnd = Math.ceil(blogData.length / limit);

  // スライスして2次元配列にする
  for (let i = 0; i < loopEnd; i++) {
    let start = i * limit;
    let end = start + limit;
    resultArray.push(blogData.slice(start, end));
  }

  return resultArray;
};
